import {
  MatchDetail,
  MatchesList,
  Casino,
  Login,
  BeforeLoginDetail,
  Statement,
  ProfitLoss,
  ChangePassword,
  OddSettings,
  Game,
  MatkaDetails,
  ChartDetails,
  BeforeLoginMatkaDetail,
  BeforeLoginMatkaChartDetail,
  UserProfile,
  Deposit,
  BetHistory,
  Withdrawal,
  BankAccount,
  InstantPay,
  Bonus,
} from "App/Pages";

export const routerList = [
  {
    path: "/games",
    element: Game,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/upcoming",
    element: MatchesList,
    private: 1,
    sidebar: 0,
  },

  {
    path: "/login",
    element: Login,
    private: 0,
    sidebar: 0,
  },
  {
    path: "/in-play",
    element: Login,
    private: 0,
    sidebar: 0,
  },

  {
    path: "/bet-history",
    element: BetHistory,
    private: 1,
    sidebar: 0
  },

  {
    path: "/withdrawal",
    element: Withdrawal,
    private: 1,
    sidebar: 0
  },

  {
    path: "/deposit",
    element: Deposit,
    private: 1,
    sidebar: 0
  },

  {
    path: "/bank-account",
    element: BankAccount,
    private: 1,
    sidebar: 0
  },

  {
    path: "/all-sports",
    element: MatchesList,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/inplay",
    element: MatchesList,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/live-position",
    element: MatchesList,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/favorite",
    element: MatchesList,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/cup-winner",
    element: MatchesList,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/casino",
    element: Casino,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/casino/provider/:provider_code",
    element: Casino,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/casino/:provider_code/:game_code",
    element: Casino,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/casino-market",
    element: Casino,
    private: 0,
    sidebar: 0,
  },
  {
    path: "/match-detail/:event_id",
    element: MatchDetail,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/detail/:event_id",
    element: BeforeLoginDetail,
    private: 0,
    sidebar: 0,
  },
  {
    path: "/matka-detail/:event_id",
    element: BeforeLoginMatkaDetail,
    private: 0,
    sidebar: 0,
  },
  {
    path: "/matka-chart-detail/:event_id/:cate_id",
    element: BeforeLoginMatkaChartDetail,
    private: 0,
    sidebar: 0,
  },
  {
    path: "/statement",
    element: Statement,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/profit-loss",
    element: ProfitLoss,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/setting",
    element: OddSettings,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/profile",
    element: UserProfile,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/change-password",
    element: ChangePassword,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/matka/:event_id",
    element: MatkaDetails,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/matka-chart/:event_id/:cate_id",
    element: ChartDetails,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/instant-pay/:amount/:merchantTransactionId/",
    element: InstantPay,
    private: 1,
    sidebar: 0,
  },
  {
    path: "/bonus",
    element: Bonus,
    private: 1,
    sidebar: 0,
  },
];

export { ProtectRoutes } from "./ProtectRoutes";
export { PublicRoute } from "./PublicRoute";
