import React, { useEffect, useState } from "react";
import CoustomTabel from "../../components/Common/CoustomTabel";
import CoustomPagination from "../../components/Common/CoustomPagination";
import { userAccountStatement } from "App/_redux/_services";
import moment from "moment-timezone";
import { getDateAndTime } from "App/utils";
import { DotLoader } from "react-spinners";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

export const Statement = () => {
  const navigate = useNavigate()
  const formatDate = "YYYY-MM-DD";
  const [dataList, setDataList] = useState({ total_count: 0, list: [] });
  const todayDate = moment(new Date()).startOf("isoWeek");
  const [filterData, setFilter] = useState({
    startDate: todayDate.clone().subtract(12, "days").format(formatDate),
    endDate: moment(new Date()).format(formatDate),
    current_page: 1,
    items_perpage: 20,
  });
  const [loading, setLoading] = useState(false);
  const [forceFetch, setToggleFetch] = useState(false);

  useEffect(() => {
    if (forceFetch) {
      getDataList(1);
    }
  }, [forceFetch]);

  useEffect(() => {
    getDataList(filterData?.current_page);
  }, [filterData?.current_page]);

  const getDataList = async (current_page) => {
    const formattedStartDate =
      moment(filterData?.startDate).format(formatDate) + " " + "00:00:00";
    const formattedEndDate =
      moment(filterData?.endDate).format(formatDate) + " 23:59:59";
    const payload = {
      from_date: moment(formattedStartDate)
        .utc()
        .format(formatDate + " HH:mm:ss"),
      to_date: moment(formattedEndDate)
        .utc()
        .format(formatDate + " HH:mm:ss"),
      current_page: current_page,
      items_perpage: filterData?.items_perpage,
    };
    setLoading(true);
    try {
      const res = await userAccountStatement(payload);
      if (res?.data?.coin_report) {

        res?.data?.coin_report.map((item, i) => {
          item.created_at = getDateAndTime("MMMM DD, hh:mm A", item?.created_at)
          return item
        })

        setDataList({
          total_count: res?.data?.total,
          list: res?.data?.coin_report,
        });
        setLoading(false);
      } else {
        setLoading(false);
        setDataList({ total_count: 0, list: [] });
      }
    } catch (error) {
      setLoading(false);
    }
    setToggleFetch(false);
    setFilter((s) => ({ ...s, current_page }));
  };

  const columnData = [
    { name: "Date Time", value: "created_at" },
    { name: "Remark", value: "remark" },
    { name: "Credit", value: "credit_amount" },
    { name: "Debit", value: "debit_amount" },
    { name: "Profit/Loss", value: "profit_loss" },
    { name: "Balance", value: "user_balance" },
  ];

  const handleSelectDate = (name, value) => {
    setFilter((s) => ({ ...s, [name]: value }));
  };

  const handlePageClick = ({ selected }) => {
    setFilter((s) => ({ ...s, current_page: selected + 1 }));
  };

  return (
    <div className="innrRight_singleBx">
      {/* /search-bar/ */}
      <div className="bg-[#F8F8F8] dark:bg-[#22262A] p-[10px_18px_10px_18px] xl:p-[10px_20px_10px_30px] rounded">
        <div className="statementFlx">
          <div>
            <h4 className="h4_semiTxt flex items-center gap-2"> <AiOutlineArrowLeft className=' cursor-pointer' onClick={() => navigate(-1)} /> Account Statement</h4>
          </div>

          <div className="searchFlexBx w-full lg:w-[calc(100%-220px)] xl:w-[calc(100%-300px)] justify-start lg:justify-end gap-y-4">
            <div className="inputsFrt_fx basis-full sm:basis-[426px]">
              <div className="inpBasis inpBasisFlex">
                <label className="lableTxt" htmlFor="">
                  Start Date
                </label>
                <input
                  type="date"
                  className="inpBx w-[calc(100%-70px)] sm:w-auto"
                  value={filterData?.startDate}
                  max={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    handleSelectDate("startDate", e.target.value)
                  }
                />
              </div>

              <div className="inpBasis inpBasisFlex">
                <label className="lableTxt" htmlFor="">
                  End Date
                </label>
                <input
                  type="date"
                  className="inpBx w-[calc(100%-70px)] sm:w-auto"
                  value={filterData?.endDate}
                  min={filterData?.startDate}
                  max={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    handleSelectDate("endDate", e.target.value)
                  }
                />
              </div>
            </div>

            <div className="inputsSecd_fx basis-auto">
              <button
                disabled={loading}
                className="srchBtn"
                onClick={() => setToggleFetch(true)}
              >
                Search
              </button>
            </div>
          </div>


        </div>
      </div>
      {loading ?
        <div className="flex justify-center items-start text-center py-12">
          <div className="px-24 py-8 bg-[#F5F5F5] dark:bg-[#22262A] flex justify-center items-center">
            <DotLoader color="#f2d61d" />
          </div>
        </div>
        :
        <div className="table_controler">
          <CoustomTabel columnData={columnData} dataList={dataList?.list} />
          {dataList?.total_count > 0 && (
            <CoustomPagination
              initialPage={filterData?.current_page - 1}
              handlePageClick={handlePageClick}
              pageCount={Math.ceil(
                dataList?.total_count / filterData?.items_perpage
              )}
            />
          )}
        </div>}
    </div>
  );
};
