import React, { Fragment } from 'react'
import { getSelectedSportRequest, toggleAuthModalRequest } from "App/_redux/_actions";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Images } from 'App/constants';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import _ from "App/helper/_";
import { useScreenDetector } from 'App/hooks/useScreenDetector';


const SportTabs = () => {
    const { isMobile } = useScreenDetector();
    let dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const { selectedSportId, selectedSportTab, masterData, bannerList } = useSelector((state) => state.match);

    const { pathname } = useLocation();


    const getSport = (data, type) => {
        const param = { type: type == "1" ? 1 : type == "2" ? 2 : 1 }
        if (currentUser.user_id) {
            param['user_id'] = currentUser.user_id
        }
        dispatch(getSelectedSportRequest(data))
    }

    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }


    const dummyFun = () => { }


    return (
        <React.Fragment>
            {(pathname.slice(0, pathname.lastIndexOf('/')) != "/detail" && pathname != "/favorite" && pathname != "/inplay") ?
                <Fragment>
                    {bannerList && bannerList.list && bannerList.list.length > 0 &&
                        <Carousel autoPlay showThumbs={false} infiniteLoop className='md:rounded overflow-hidden mt-0 mb-2 md:my-2 anvCarousal_control'>
                            {bannerList.list.map((item, key) => (
                                <div className='crs-imgOtr'><img src={isMobile ? item.mobile_banner : item.desktop_banner} /></div>
                            ))}
                        </Carousel>
                    }

                    {masterData && masterData.poular_casinos &&
                        <div className='flex overflow-auto no-scrollbar w-full'>
                            {masterData.poular_casinos.map((item, key) => {
                                return (
                                    <div className='Casinos-slide ' key={key}>
                                        <Link to={`/casino/${item.providerCode}/${item.code}`} className="cursor-pointer " onClick={() => { !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? dummyFun() : toggleAuthModal("LOGIN") }}>
                                            <img src={item.thumb} alt="" className="w-full h-full rounded" />
                                        </Link>
                                    </div>
                                )
                            })}

                        </div>}
                </Fragment>
                :
                (pathname == "/favorite" || pathname == "/inplay") ?
                    <div className='flex text-[16px] font-[500] leading-[19.36px] ml-2.5 md:ml-0 my-4 text-[#424242] dark:text-white'>
                        {pathname == "/favorite" && <Fragment><img src={Images.STAR.default} alt="" className='h-5 w-5 mr-2' /><span>Favorite</span></Fragment>}
                        {pathname == "/inplay" && <Fragment><img src={Images.PLAY.default} alt="" className='h-5 w-5 mr-2' /><span>In-Play</span></Fragment>}
                    </div>
                    : null
            }

            {(pathname == "/all-sports" || pathname == "/inplay" || pathname == "/upcoming" || pathname == "/live-position" || pathname == "/favorite" || pathname == "/cup-winner" || (pathname == "/login" && selectedSportTab.link != "/casino")) &&
                <div className="bg-[#F9F9F9] dark:bg-[#111111]  border-b border-[#F0F0F0] dark:border-[#171717] rounded-lg pt-3">
                    <div className=" max-w-[1377px] mx-auto flex overflow-auto sm:!overflow-visible flex-wrap no-scrollbar">
                        <ul className="h-full flex whitespace-nowrap pb-2 px-2 sm:px-4 gap-[10px] sm:gap-3 justify-between sm:justify-start w-full">
                            <li className="h-full">
                                <button
                                    className={"h-full relative flex items-center justify-center " +
                                        ((selectedSportId.sports_id == "" && selectedSportId.sports_name == 'All') ? "anvTab_btnActive" : "")
                                    }
                                    onClick={() => getSport({ sports_id: "", sports_name: "All" })}
                                >
                                    <div className="flex flex-col items-center gap-2">
                                        <div className="anvNotify_circ">
                                            <img className="mx-auto" src={Images.ALL_PNG} alt={""} />

                                        </div>
                                        <p className="text-[12px] md:text-sm text-[#616161] dark:text-[#b1b1b1] font-medium leading-[1]">
                                            {"All"}
                                        </p>
                                    </div>

                                </button>
                            </li>
                            {masterData && masterData.sports_master && masterData.sports_master.length > 0 &&
                                masterData.sports_master.map((item, key) => (
                                    <Fragment>
                                        {item.sport_id != 6 && <li className="h-full" key={item?.sport_id + item.sports_name}>
                                            <button
                                                className={"h-full relative flex items-center justify-center " +
                                                    ((selectedSportId.sports_id == item.sport_id && selectedSportId.sports_name == item.sports_name) ? "anvTab_btnActive" : "")
                                                }
                                                onClick={() => getSport({ sports_id: item.sport_id, sports_name: item.sports_name })}>
                                                <div className="flex flex-col items-center gap-2">
                                                    <div className="anvNotify_circ">
                                                        <img className="mx-auto" src={item.icon} alt={item?.sports_name} />
                                                    </div>
                                                    <p className="text-[12px] md:text-sm text-[#616161] dark:text-[#b1b1b1] font-medium leading-[1]">
                                                        {item?.sports_name}
                                                    </p>
                                                </div>
                                            </button>
                                        </li>}
                                    </Fragment>
                                ))}
                        </ul>
                    </div>
                </div>}

        </React.Fragment>
    )
}

export default SportTabs