import React from "react";
import { Images } from "App/constants";

export default function Footer() {
  return (
    <footer className=" footerMain_bx">

      <div className="anvCopy_write">

        <ul className="ft_contentLeft_ul">
          <li>
            <a href="">
              Promotions
            </a>
          </li>

          <li>
            <a href="">
              Terms and Conditions
            </a>
          </li>

          <li>
            <a href="">
              Betting Rules
            </a>
          </li>

          <li>
            <a href="">
              Virtual Rules
            </a>
          </li>

          <li>
            <a href="">
              Responsible Gambling
            </a>
          </li>

          <li>
            <a href="">
              Privacy Policy
            </a>
          </li>
        </ul>

        <div className="my-8 px-[12px]">
          <p className="text-sm text-[#F1F1F1] text-left md:text-center opacity-70 md:opacity-100">Please be aware that any data (such as current scores, elapsed time, video and other data) that is displayed on the Website is sourced from a 'live feed' provided by a third party and may be subject to time delay and/or be inaccurate. If you rely on this data to place bets, you do so entirely at your own risk and we accept no responsibility for any loss (direct or indirect) suffered by you as a result of your reliance on it.</p>
        </div>

        <div className="pt-[15px] flex flex-col gap-[20px]">
          <div><p className="text-[#F1F1F1] text-sm text-center">Restricted to <span className="font-semibold"> 18+</span></p></div>

          <div className="flex gap-[25px] md:gap-[70px] items-end flex-wrap">
            <img src={Images.GROUPCOPY1} alt="" />
            <img className="max-w-[400px] w-full md:w-auto md:max-w-[100%]" src={Images.GROUPCOPY2} alt="" />
          </div>
        </div>
      </div>

    </footer>
  );
}
