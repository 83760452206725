import React from "react";
import {
  appendStackValue,
  commentaryType,
  convertParseFloat,
  Utils
} from "App/utils";
import _ from "App/helper/_"
import { setBetRequest } from "App/_redux/_actions";
import { getSessionPosition } from "App/_redux/_services";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import PlaceMarketBet from "./PlaceMarketBet";

class SessionDetail extends React.Component {
  state = {
    positionData: [],
    width: window.innerWidth,
    height: window.innerHeight,
    detailsId: [],
    selectedBetslip: '',
    selectedBetslipType: '',
    SelectedOddsFancy: {},
    betslip_lastupdate: new Date().valueOf(),
    is_betslip_drawer: false
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  setBet = (bettype, price, session) => {
    let betData = {
      event_id: session.event_id,
      market_id: session.session_id,
      selectionId: session.session_id,
      name: session.runnerName,
      market_name: session.market_name,
      price,
      bookmaker_type: 3,
      bettype,
    };
    this.props.dispatch(setBetRequest(betData));
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
  };

  // getFancyPositionData = async () => {
  //   let arrObj = [];
  //   // let v = [];
  //   this.props.sessionList.forEach(async (market) => {
  //     let sendData = {};
  //     sendData.event_id = market.event_id;
  //     sendData.session_id = market.session_id;
  //     // let response = await getSessionPosition(sendData);
  //     getSessionPosition(sendData)
  //       .then((response) => {
  //         if (
  //           response &&
  //           response.data &&
  //           response.data.runners &&
  //           response.data.runners.length
  //         ) {
  //           let runnersData = JSON.parse(JSON.stringify(response.data.runners));
  //           runnersData.forEach((val) => (val.session_id = market.session_id));
  //           arrObj = [...arrObj, ...runnersData];
  //           this.setState({ positionData: arrObj });
  //         } else {
  //           arrObj = [];
  //         }
  //       })
  //       .catch((error) => {});
  //   });
  // };

  fetchFancyPosition = (session_id, selectionId) => {
    let position = this.state.positionData.find(
      (pos) => pos.selectionId === selectionId && pos.session_id === session_id
    );
    if (position && position.winloss) {
      return position.winloss;
    } else {
      return 0;
    }
  };

  toggleDetailsMenu = (id) => {
    const detailsArray = this.state?.detailsId;
    if (detailsArray.indexOf(id) >= 0) {
      detailsArray.splice(detailsArray.indexOf(id), 1);
    } else {
      detailsArray.push(id);
    }
    this.setState({ detailsArray: detailsArray });
  };

  openMarketBetSlip = (selectionId, type) => {
    const { openBetSlipMarketId } = this.props;
    openBetSlipMarketId(selectionId);
    this.setState({
      selectedBetslip: selectionId,
      selectedBetslipType: type,
    })
    // console.log("openBetSlipMarketId==>>", openBetSlipMarketId)
    // console.log("selectedBetslip==>>", this.state.selectedBetslip)
    // console.log("selectedBetslipType==>>", this.state.selectedBetslipType)
  }

  closeMarketBetSlip = () => {
    this.setState({ selectedBetslip: '', selectedBetslipType: '' }, () => {
      if (this.state.is_betslip_drawer) {
        const { getOpenBet, openBetSlipMarketId } = this.props;
        getOpenBet();
        openBetSlipMarketId('');
      }
    })
  }

  handleRemoveSlip = (key) => {
    this.setState({
      SelectedOddsFancy: {},
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip('fancy_betting', this.state.SelectedOddsFancy)
    })
  }

  clearAllBet = (BetslipType) => {
    this.setState({
      SelectedOddsFancy: {},
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.removeBetSlip(BetslipType)
      Utils.removeMultiBetSlip(BetslipType)
    })
  }

  _fancyHandleOptionChange = (option) => {
    let { SelectedOddsFancy } = this.state;
    const { fixture, odd, betting_type, selected_odd } = option;

    SelectedOddsFancy[0] = {
      picked_odd_id: odd.market_bet_id + '_' + odd.selection_id + '_' + betting_type,
      market_bet_id: odd.market_bet_id,
      picked_odd_price: betting_type === 1 ? odd.price : odd.lay_price,
      picked_odd: { ...odd, betting_type, name: odd.runner_name, ...selected_odd },
      stack_value: '',
      ...fixture,
    }
    this.setState({
      SelectedOddsFancy,
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip('fancy_betting', this.state.SelectedOddsFancy)
    })
  }

  _fancyHandleRemoveSlip = (key) => {
    let SelectedOddsFancy = Utils.getBetSlip('fancy_betting');
    let newSelectedOddsFancy = _.omit(SelectedOddsFancy, key)
    this.setState({
      SelectedOddsFancy: newSelectedOddsFancy,
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip('fancy_betting', this.state.SelectedOddsFancy)
    })
  }

  render() {
    let {
      matchDetailEvents,
      sessionList,
      setBookmakerType,
      setBettingType,
      setPrice,
      setDispalyPrice,
      setMarketId,
      setSelectionId,
      setToggleBetForm,
      setName,
      setTeamName,
      setBatType,
      scrollToBottom,
      setSelectedData,
      showSessionBook,
      openBetSlipid,
      EventDetails,

      toggleBetForm,
      betPriceDetail,
      betAmount,
      setBetAmount,
      placeBetRequest,
      onDonePressed,
      price,
      displayPrice,
      name,
      teamName,
      batType,
      event,
      selectedData,
      oddsStack,
      setOpenSettingModal,
      width
    } = this.props;

    const { SelectedOddsFancy, betslip_lastupdate } = this.state

    const BetslipProps = {
      EventDetails,
      SelectedOdds: SelectedOddsFancy,
      betslip_lastupdate,
      page_id: 'fancy_betting',
      handleRemoveSlip: this.handleRemoveSlip,
      clearAllBet: this.clearAllBet,
      closeMarketBetSlip: this.closeMarketBetSlip,
    }


    return (
      sessionList?.data &&
      sessionList?.data.filter((item) => !item.bet_lock).length > 0 && (

        <div className="overflow-auto rounded-[4px] mt-2">
          <table className="w-full anvWin_table">
            <thead>
              {!this.state.detailsId.includes(sessionList?.session?.name) && (
                <tr className="customGradient2">
                  <th className="trLeft">
                    <div>
                      <p className="mediumPara marketHeading"> {sessionList?.session?.name}</p>
                      <p className="xtra_smallPara">

                        {/* {matchDetailEvents?.["min" + sessionList?.session?.value] || matchDetailEvents?.["max" + sessionList?.session?.value] ? `Min:${appendStackValue("" +
                            matchDetailEvents?.["min" + sessionList?.session?.value])} | Max:${appendStackValue("" + matchDetailEvents?.["max" + sessionList?.session?.value])}`
                            : null} */}
                        {matchDetailEvents?.["min" + sessionList?.session?.value] || matchDetailEvents?.["max" + sessionList?.session?.value] ? `(MIN : ${matchDetailEvents?.["min" + sessionList?.session?.value]} MAX : ${matchDetailEvents?.["max" + sessionList?.session?.value] + ")"}`
                          : null}
                      </p>
                    </div>
                  </th>

                  <th className="trRight">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="w-[50%] pl-[14px]">
                            <div className="text-center leading-[1.3]">
                              <p className="layBackHeading">NO</p>
                              {/* <p className="xtra_smallPara">LAGAI</p> */}
                            </div>
                          </th>

                          <th className="w-[50%] pl-[35px]">
                            <div className="text-center leading-[1.3]">
                              <p className="layBackHeading">YES</p>
                              {/* <p className="xtra_smallPara">KHAI</p> */}
                            </div>
                          </th>
                          <th>
                            <div className="pr-2 cursor-pointer" onClick={() => this.setState({ [sessionList?.session?.name]: this.state[sessionList?.session?.name] == false ? true : false })}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none" className={"scale-[0.8] " + (this.state[sessionList?.session?.name] == false ? "rotate-180" : "")}>
                                <path d="M1.48785 2.00005L7.01228 7.97756L12.4878 1.95517" className=" stroke-[#000] dark:stroke-[#fff]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </th>
                </tr>)}

            </thead>
            {(this.state[sessionList?.session?.name] == undefined || this.state[sessionList?.session?.name]) && !this.state.detailsId.includes(sessionList?.session?.name) && (
              <tbody>

                {sessionList?.data
                  .filter((item) => !item.bet_lock)
                  .map((session, index) => (
                    <>
                      <tr key={index}>
                        <td className="firstTd_tab tabTd_border border-r-0">
                          <div className="grid grid-cols-4 pr- align-items-center">
                            <div className="col-span-3">
                              <p className="mediumPara marketHeading font-[500]">{session.runner_name}</p>
                            </div>

                            <div className=" text-right">
                              <button className="bookBtn" onClick={() => showSessionBook(session?.session_id)}><span className="relative z-[9]">Book</span></button>
                            </div>
                          </div>
                        </td>


                        <td className="p-0 tabTd_border border-l-0">
                          <table className="w-full relative">
                            <tbody>
                              <tr className="relative">

                                <td className="backLagai cursor-pointer"
                                  onClick={() => {
                                    if (session.lay_price1 > 0) {
                                      this.openMarketBetSlip(session.session_id, 'lay')
                                      this._fancyHandleOptionChange({ fixture: { ...session, sports_id: EventDetails.sports_id }, odd: { ...session, lay_price_1: session.lay_price_1, lay_size_1: session.lay_size_1 }, betting_type: 2, selected_odd: { price: session.lay_price_1, size: '' } })
                                      setBookmakerType(3);
                                      setBettingType(2);
                                      setPrice(session.lay_price1);
                                      setDispalyPrice(session.lay_price1);
                                      setMarketId(session.session_id);
                                      setSelectionId(session.session_id);
                                      setName("SESSION");
                                      setSelectedData(session);
                                      setTeamName(session.runner_name);
                                      setBatType("NO");
                                      setToggleBetForm(true);
                                    }
                                    // scrollToBottom(index + session.runner_name);

                                    // if (
                                    //   this.props.handleCommentaryStatusModal(
                                    //     commentaryType[5]
                                    //   )
                                    // ) {
                                    //   return;
                                    // }


                                  }}


                                >
                                  <div className="noBgs ">
                                    <p className="mediumPara">
                                      {matchDetailEvents?.btn_color &&
                                        matchDetailEvents.btn_color == "Red" &&
                                        matchDetailEvents.commentary_button_id != 3
                                        ? 0
                                        : session.lay_price1}
                                    </p>
                                    <p className="xtra_smallPara -mt-1">{session.lay_size1}</p>
                                  </div>
                                </td>

                                <td className="layKhai cursor-pointer"
                                  onClick={(e) => {
                                    if (session.back_price1 > 0) {
                                      this.openMarketBetSlip(session.session_id, 'back')
                                      this._fancyHandleOptionChange({ fixture: { ...session, sports_id: EventDetails.sports_id }, odd: { ...session, back_price_1: session.back_price_1, back_size_1: session.back_size_1 }, betting_type: 1, selected_odd: { price: session.back_price_1, size: '' } })
                                      setBookmakerType(3);
                                      setBettingType(1);
                                      setPrice(session.back_price1);
                                      setDispalyPrice(session.back_price1);
                                      setMarketId(session.session_id);
                                      setSelectionId(session.session_id);
                                      setName("SESSION");
                                      setSelectedData(session);
                                      setTeamName(session.runner_name);
                                      setBatType("YES");
                                      setToggleBetForm(true);
                                    }
                                    // if (
                                    //   this.props.handleCommentaryStatusModal(
                                    //     commentaryType[5]
                                    //   )
                                    // ) {
                                    //   return;
                                    // }

                                  }}

                                >
                                  <div className="yesBgs relative">
                                    <p className="mediumPara">  {matchDetailEvents?.btn_color &&
                                      matchDetailEvents.btn_color == "Red" &&
                                      matchDetailEvents.commentary_button_id != 3
                                      ? 0
                                      : session.back_price1}</p>
                                    <p className="xtra_smallPara -mt-1">{session.back_size1}</p>
                                  </div>
                                </td>
                                {session.game_status && session.game_status != "" && (
                                  <div className="notAllow">
                                    {session.game_status}
                                  </div>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      {toggleBetForm && this.state.selectedBetslip == session.session_id && openBetSlipid == session.session_id &&
                        <tr>
                          <td colSpan='3'>
                            <PlaceMarketBet
                              betPriceDetail={betPriceDetail}
                              betAmount={betAmount}
                              setBetAmount={setBetAmount}
                              placeBetRequest={placeBetRequest}
                              setToggleBetForm={setToggleBetForm}
                              onDonePressed={onDonePressed}
                              price={price}
                              displayPrice={displayPrice}
                              name={name}
                              teamName={teamName}
                              batType={batType}
                              event={event}
                              selectedData={selectedData}
                              oddsStack={oddsStack}
                              setOpenSettingModal={setOpenSettingModal}
                              openMarketBetSlip={() => this.openMarketBetSlip(session.session_id, 'back',)}
                              min_max={matchDetailEvents?.["min" + sessionList?.session?.value] || matchDetailEvents?.["max" + sessionList?.session?.value] ? `Min : ${matchDetailEvents?.["min" + sessionList?.session?.value]} Max : ${matchDetailEvents?.["max" + sessionList?.session?.value] + ""}`
                                : null}

                            />
                          </td>
                        </tr>
                      }
                    </>
                  ))}
              </tbody>)}
          </table>


        </div>

      )
    );
  }
}

export default React.memo(SessionDetail);
