import React, { Fragment, useState, useEffect } from 'react'
import CoustomPagination from "App/components/Common/CoustomPagination";
import {
    getMarketSessionBet,
} from "App/_redux/_services";
import _ from "App/helper/_"
import { Utils } from "App/utils"

const BetslipOpenBet = ({ event_id }) => {
    const [state, setAllStateValue] = useState({
        marketBetData: "",
        sessionBetData: "",
        betSlipType: "",
        is_loading: false,
        Offset: 0,
        current_page: 1,
        items_perpage: 2,
        totalPage: 0,
        pageRangeDisplayed: 5,
        market_type: null,
        filter: null,
        page_changed: false
    });

    const setState = (obj, callback) => {
        setAllStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };


    // const fetchMarketBet = (market_type, filter) => {
    const fetchMarketBet = () => {
        setState({
            marketBetData: [],
            is_loading: true,
            betSlipType: state.betSlipType === state.market_type && !state.page_changed ? "" : state.market_type,
            betSlipType: state.market_type,
        });
        let param = {
            items_perpage: state.items_perpage,
            current_page: state.current_page,
            filter: state.filter,
            event_id: event_id,
        };
        // param.items_perpage = 100;
        // param.current_page = 1;
        // param.filter = filter;
        // param.event_id = event_id;

        if (state.betSlipType !== state.market_type || state.page_changed) {
            // if (true) {
            getMarketSessionBet(param)
                .then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.open_bets &&
                        response.data.open_bets.length
                    ) {
                        let tempNumber = response.data.total_records / state.items_perpage;
                        setState({ marketBetData: response.data.open_bets, is_loading: false, totalPage: tempNumber });
                    } else {
                        setState({ marketBetData: [], is_loading: false, totalPage: 0 });
                    }
                })
                .catch((error) => {
                    setState({ marketBetData: [], is_loading: false, totalPage: 0 });
                });
        }
    };

    const handlePageClick = ({ selected }) => {
        setState({ current_page: selected + 1, page_changed: true }, () => {
            // fetchMarketBet();
        });
    };

    const getMarket = (market_type, filter) => {
        if (market_type == state.betSlipType) {
            setState({ betSlipType: "", market_type: null, filter: null, current_page: 1, totalPage: 0, page_changed: false });
        }
        else {
            setState({ market_type, filter, current_page: 1, totalPage: 0, page_changed: false });
        }
    };

    useEffect(() => {
        if (state?.filter != null) { fetchMarketBet(); }
    }, [state?.filter]);

    useEffect(() => {
        if (state?.current_page != 1 || state.page_changed) { fetchMarketBet(); }
    }, [state.current_page]);

    const { betSlipType, marketBetData, is_loading } = state
    return (
        <div className="matchOdd_tabBx">
            <div className="flex anvScor_bord justify-around whitespace-nowrap ">
                <button className={"betsBtn-primary " + (betSlipType === "Match_odd" ? "scorActive" : "")} onClick={() => getMarket("Match_odd", 1)}>Match Odd</button>
                <button className={"betsBtn-primary " + (betSlipType === "Bookmaker" ? "scorActive" : "")} onClick={() => getMarket("Bookmaker", 2)}>Bookmaker</button>
                <button className={"betsBtn-primary " + (betSlipType === "Fancy" ? "scorActive" : "")} onClick={() => getMarket("Fancy", 3)}>Fancy</button>
                {/* <button className="{betsBtn-primary "+(betSlipType==="Oth_fancy"?"scorActive":"")} onClick={() => fetchSessionBet("Oth_fancy", 3)}>OTH. FAN.</button> */}
                <button className={"betsBtn-primary " + (betSlipType === "Oth_market" ? "scorActive" : "")} onClick={() => getMarket("Oth_market", 5)}>OTH. MKT.</button>
            </div>

            {betSlipType != "" && (
                <Fragment>
                    {is_loading ? <p className='dark:text-white text-center text-sm py-1'>Loading...</p> :
                        <Fragment>
                            {marketBetData.length > 0 ?
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                            <div className="overflow-hidden ">
                                                <table class="min-w-full text-left text-sm anvWin_table">
                                                    <thead>
                                                        <tr className=" text-nowrap">
                                                            <th scope="col" className="firstTd_tab tabTd_border">
                                                                <div className="">
                                                                    <p className="mediumPara">S.NO</p>
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="firstTd_tab tabTd_border">
                                                                <div className="">
                                                                    <p className="mediumPara">RUNNER</p>
                                                                </div>
                                                            </th>

                                                            {state.filter && state.filter == 3 && <th scope="col" className="firstTd_tab tabTd_border">
                                                                <div className="">
                                                                    <p className="mediumPara">RUN</p>
                                                                </div>
                                                            </th>}
                                                            <th scope="col" className="firstTd_tab tabTd_border">
                                                                <div className="">
                                                                    <p className="mediumPara">RATE</p>
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="firstTd_tab tabTd_border">
                                                                <div className="">
                                                                    <p className="mediumPara">MARKET NAME</p>
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="firstTd_tab tabTd_border">
                                                                <div className="">
                                                                    <p className="mediumPara">AMOUNT</p>
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="firstTd_tab tabTd_border">
                                                                <div className="">
                                                                    <p className="mediumPara">MODE</p>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            _.map(marketBetData, (item, idx) => {

                                                                return (
                                                                    <tr key={idx} className={
                                                                        item.child_session_type_id != 10 ? (
                                                                            Number(item.bookmaker_type) !== 3 ?
                                                                                (item.betting_type == 1 ? ("lagaiColor") : ("khaiColor"))
                                                                                : (item.betting_type == 1 ? ("lagaiColor") : ("khaiColor"))) : ""}>
                                                                        <td className="firstTd_tab tabTd_border">
                                                                            <div>
                                                                                <p className="mediumPara ">{idx + 1}</p>
                                                                            </div>
                                                                        </td>

                                                                        <td className="firstTd_tab tabTd_border ">
                                                                            <div>
                                                                                <p className="mediumPara ">
                                                                                    {item.market_runners !== null ?
                                                                                        _.map(item.market_runners, (ritm, rid) => {
                                                                                            return (
                                                                                                item.selection_id == ritm.selectionId ? ritm.runnerName : ''
                                                                                            )
                                                                                        }) : item.market_name}
                                                                                </p>
                                                                            </div>
                                                                        </td>

                                                                        {state.filter && state.filter == 3 && <td className="firstTd_tab  tabTd_border ">
                                                                            <div>
                                                                                <p className="mediumPara ">{item.child_session_type_id == 10 ? (item.betting_type == 1 ? 'ODD' : 'EVEN') : item.line}</p>
                                                                            </div>
                                                                        </td>}
                                                                        <td className="firstTd_tab tabTd_border ">
                                                                            <div>
                                                                                <p className="mediumPara ">{
                                                                                    Number(item.bookmaker_type) !== 3 ?
                                                                                        (betSlipType === "Match_odd" ? Utils.TwoDecimalVal(item.price - 1) : Utils.TwoDecimalVal(item.price)) :
                                                                                        Utils.TwoDecimalVal(Number(item.price) - 1)
                                                                                }</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="firstTd_tab tabTd_border ">
                                                                            <div>
                                                                                <p className="mediumPara ">{item.market_name}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="firstTd_tab tabTd_border ">
                                                                            <div>
                                                                                <p className="mediumPara ">{item.stake}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="firstTd_tab tabTd_border ">
                                                                            <div>

                                                                                {item.child_session_type_id != 10 ?
                                                                                    <p className="mediumPara ">{Number(item.bookmaker_type) !== 3 ? (item.betting_type == 1 ? ("Lagai") : ("Khai")) : (item.betting_type == 1 ? ("Yes") : ("No"))}</p>
                                                                                    : <p className="mediumPara ">{item.betting_type == 1 ? 'ODD' : 'EVEN'}</p>}
                                                                            </div>
                                                                        </td>


                                                                    </tr>

                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                {state.totalPage > 0 && (
                                                    <CoustomPagination
                                                        initialPage={state?.current_page - 1}
                                                        handlePageClick={handlePageClick}
                                                        pageCount={Math.ceil(
                                                            state.totalPage
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div> : <p className='dark:text-white text-center text-sm py-1'>No Record Found.</p>}
                        </Fragment>
                    }
                </Fragment>
            )
            }
        </div>
    )
}

export default BetslipOpenBet