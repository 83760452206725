import { Images } from 'App/constants'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import _ from "App/helper/_";
import { toggleAuthModalRequest } from 'App/_redux/_actions';

const BottomNavBar = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { currentUser } = useSelector((state) => state.auth);
    const { selectedSportTab } = useSelector((state) => state.match);

    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }
    return (
        <div className="btm-nav md:hidden bottomNav">
            <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/all-sports' : '/login'} className={({ isActive }) => `${isActive ? "active" : ""}`}>
                <img src={pathname == (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/all-sports' : '/login') ? Images.ABN_HOME.default : Images.BN_HOME.default} alt="" />
                <span className="btm-nav-label">Home</span>
            </NavLink>
            <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/inplay' : '/in-play'} className={({ isActive }) => `${isActive ? "active" : ""} ${selectedSportTab.link == '/inplay' ? "active" : ""}`} >
                <img src={pathname == (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/inplay' : '/in-play') ? Images.ABN_INPLAY.default : Images.BN_INPLAY.default} alt="" />
                <span className="btm-nav-label">In Play</span>
            </NavLink>
            <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/casino' : '/casino-market'} className={({ isActive }) => `${isActive ? "casinoActive" : ""}  mt-[-30px]`}>
                <div className='casino'>
                    <img src={Images.BN_CASINO.default} alt="" />
                </div>
                <span className="btm-nav-label">Casino</span>
            </NavLink>
            <NavLink to={"/statement"} onClick={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? () => { } : () => toggleAuthModal("LOGIN")} className={({ isActive }) => `${isActive ? "active" : ""}`}>
                <img src={pathname == '/statement' ? Images.ABN_ACCOUNT.default : Images.BN_ACCOUNT.default} alt="" />
                <span className="btm-nav-label">Account</span>
            </NavLink>
            <NavLink to={"/profile"} onClick={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? () => { } : () => toggleAuthModal("LOGIN")} className={({ isActive }) => `${isActive ? "active" : ""}`}>
                <img src={pathname == '/profile' ? Images.ABN_PROFILE.default : Images.BN_PROFILE.default} alt="" />
                <span className="btm-nav-label">Profile</span>
            </NavLink>
        </div>
    )
}

export default BottomNavBar