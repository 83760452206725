import React from 'react'

const UserLimitModal = ({ toggle, userLimit }) => {
    return (
        <div className="justify-center  flex fixed inset-0 z-50 outline-none focus:outline-none" >
            <div class="relative w-full max-w-xl z-10 mx-4 overflow-x-auto flex justify-center items-center">
                {/* Modal content  */}
                <div class="relative customGradient2 rounded-lg shadow ">
                    {/* Modal header  */}
                    <div class="flex items-center justify-between p-[8px_8px_5px_12px] rounded-t border-b border-gray-800">
                        <h3 class="text-xl font-semibold text-[#000] dark:text-white">
                            Bet Slip
                        </h3>
                        <button onClick={(toggle)} type="button" class="anvModal_close text-black" data-modal-hide="authentication-modal">
                            <svg class="w-3 h-3" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="" className='stroke-black dark:stroke-white' strokeLinecap="round" strokeLnejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* Modal body  */}

                    {userLimit.length ? <div className=" max-h-[68vh] sm:max-h-[65vh] rounded-b-lg overflow-auto">
                        <table className="sessionTable anvBet_slip">
                            <thead>
                                <tr className="sessionBkHdr sticky top-0">
                                    <th className="">
                                        <div>
                                            <p className="mediumPara text-color">Event Name</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            <p className="mediumPara text-color">Market Name</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            <p className="mediumPara text-color">Stack</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {userLimit &&
                                    userLimit.map((item, index) => {
                                        return (
                                            <tr key={item.range + index + item.net_pl}>
                                                <td className=" tabTd_border">
                                                    <div>
                                                        <p className="mediumPara font-semibold dark:text-white _inTwoLine">{item?.event_name}</p>
                                                    </div>
                                                </td>
                                                <td className=" tabTd_border">
                                                    <div>
                                                        <p className="mediumPara font-semibold dark:text-white">{item.market_name}</p>
                                                    </div>
                                                </td>
                                                <td className=" tabTd_border">
                                                    <div>
                                                        <p className="mediumPara font-semibold dark:text-white">{item.stake}</p>
                                                    </div>
                                                </td>

                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div> :
                        <div className='text-center bg-gray-50 dark:bg-black text-danger p-4'>
                            <p>No record found</p>
                        </div>

                    }
                </div>
            </div>
            <div className='backDrop backdrop-blur-md'></div>
        </div >
    )
}

export default UserLimitModal