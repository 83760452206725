import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { updateOddDiff, updateStakeValue } from "App/_redux/_services";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const oddDiffList = [0.01, 0.02, 0.03, 0.04, 0.05];

function OddSettingModal({ data, callBalanceAPI }) {
  const navigate = useNavigate()
  const [formValue, setFormVlaue] = useState(data);
  const [initialValue, setInitialValue] = useState(data);

  const handleButtonValue = (value, ind) => {
    const oddsStack = [...formValue.odds_stack];
    oddsStack.splice(ind, 1, value);
    setFormVlaue((s) => ({ ...s, odds_stack: oddsStack }));
  };

  useEffect(() => {
    if (!callBalanceAPI) {
      setFormVlaue(data);
      setInitialValue(data);
    }
  }, [data]);

  const updateOddsDifference = async () => {
    const payload = {
      odd_diff: formValue.odd_diff,
    };
    const res = await updateOddDiff(payload);
    if (res) {
      callBalanceAPI && callBalanceAPI();
      toast.success(res.message ? res.message : "success");
    } else {
      toast.error(res.message ? res.message : "error");
    }
  };

  const updateOddsButtonValue = async () => {
    if (
      !formValue.odds_stack.includes("") &&
      formValue.odds_stack.every((stack) => stack >= 100)
    ) {
      const payload = {
        odds_stack: formValue.odds_stack.toString(),
      };
      const res = await updateStakeValue(payload);
      if (res) {
        callBalanceAPI && callBalanceAPI();
        toast.success(res.message ? res.message : "success");
      } else {
        toast.error(res.message ? res.message : "error");
      }
    } else {
      toast.error("button value is required");
    }
  };

  const handleClick = (e) => {
    const vale = e.target.value
    setFormVlaue((s) => ({ ...s, odd_diff: vale }))
  }
  return (
    <div className="card_container ">
      <div className="bg-[#fff] dark:bg-[#22262A] p-[10px_18px_10px_18px] xl:p-[10px_20px_10px_30px] rounded">
        <div className="flex items-center justify-between flex-wrap gap-2">
          {/* <label className="h4_semiTxt">Set odds difference</label> */}
          <h4 className="h4_semiTxt flex items-center gap-2"><AiOutlineArrowLeft className=' cursor-pointer' onClick={() => navigate(-1)} />  Set odds difference</h4>

          <div className="flex items-center max-w-40 sm:max-w-60 w-full gap-[8px] sm:gap-[16px]">
            <select
              className="form-control p-2 rounded border w-full max-w-xl"
              name="account"
              value={formValue?.odd_diff}
              onChange={(e) =>
                handleClick(e)
              }
            >
              {oddDiffList.map((val, indexVal) => (
                <option key={indexVal} value={val}>
                  {val}
                </option>
              ))}
            </select>
            <div className="d-flxe gap-1">
              <button
                // disabled={formValue?.odd_diff === initialValue?.odd_diff}
                className=" _btnSave "
                onClick={() => updateOddsDifference()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className=" setting_input_box mt-3">
        <div className="bg-[#fff] dark:bg-[#22262A] p-[12px_18px_12px_18px] xl:p-[12px_20px_15px_30px] rounded">
          <label className="h4_semiTxt mb-2">Set button values</label>
          <div className="setting_button_box grid grid-cols-2 lg:grid-cols-5 gap-3">
            {formValue?.odds_stack.map((item, ind) => (
              <div key={ind + "str"}>
                <InputGroup invalid>
                  <Form.Control
                    type="number"
                    invalid
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    className={
                      (item && item >= 100 ? "" : "input_error ") + "form-control"
                    }
                    value={item}
                    maxLength="7"
                    onChange={(e) =>
                      e.target.value.length <= 7 &&
                      handleButtonValue(e.target.value, ind)
                    }
                  />
                </InputGroup>
              </div>
            ))}
          </div>
          <div className="d-flxe gap-1 mt-3 max-w-28">
            <button
              // disabled={formValue?.odd_diff === initialValue?.odd_diff}
              className="_btnSave"
              onClick={() => updateOddsButtonValue()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div >
  );
}

export default OddSettingModal;
