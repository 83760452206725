
import React, { useEffect, useState, Fragment } from "react";
import { getAllSportRequest, getInPlayFixtureRequest, getPinnedMatchesRequest, getUpcomingFixtureRequest } from "App/_redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";


import { CasinoMarket, CasinoSidebar, FixtureListView, SportTabs } from "App/components";
import _ from "App/helper/_";
import MatkaMatchList from "../MatkaMatchList";
import { pinMatch } from "App/_redux/_services";
import { number } from "yup";
import { Images } from "App/constants";
import { DotLoader } from "react-spinners";
import { useScreenDetector } from "App/hooks/useScreenDetector";
import Footer from "App/components/Layout/Footer";
import { isWebview } from "App/utils";

const MatchesList = () => {
    const { isMobile } = useScreenDetector();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { currentUser } = useSelector((state) => state.auth);
    const { isLoader, selectedSportId, selectedSportTab, GlobalAnnouncement } = useSelector((state) => state.match);


    const [Events, setEvent] = useState([])
    const [state, setState] = useState({
        sport_id: "",
        current_page: 1,
        items_perpage: 100,
        active_tab: "",
        activeTab: 1
    })


    useEffect(() => {
        let param = {
            "sport_id": selectedSportId.sports_id ? selectedSportId.sports_id : '',
            "current_page": state.current_page,
            "items_perpage": state.items_perpage
        }

        // if (selectedSportId.sports_id == 4 && selectedSportId.sports_name == "Election") {
        if (location.pathname === "/all-sports") {
            param['all_event'] = 1
        }
        if (selectedSportId.sports_id == 333) {
            param['election_event'] = 1
        }

        if (location.pathname === "/cup-winner") {
            param['cup_event'] = 1
        }
        if (location.pathname === "/live-position") {
            param['live'] = true
        }

        const params = { type: location.pathname === "/all-sports" ? 10 : location.pathname === "/inplay" ? 1 : location.pathname === "/live-position" ? 4 : location.pathname === "/favorite" ? 3 : location.pathname === "/upcoming" ? 2 : location.pathname === "/cup-winner" ? 5 : 1 }
        if (currentUser.user_id) {
            param['user_id'] = currentUser.user_id
            params['user_id'] = currentUser.user_id
        }
        // dispatch(getAllSportRequest((params), (res) => { }));

        if (currentUser.username) {
            if (state) {
                if (location.pathname === "/all-sports")
                    dispatch(getInPlayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
                else if (location.pathname === "/inplay")
                    dispatch(getInPlayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
                else if (location.pathname === '/upcoming')
                    if (selectedSportId.sports_id == 999) {
                        dispatch(getInPlayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
                    } else {
                        dispatch(getUpcomingFixtureRequest(param, (res) => { if (res) setEvent(res) }));
                    }

                else if (location.pathname === '/live-position')
                    dispatch(getInPlayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
                else if (location.pathname === '/favorite')
                    dispatch(getPinnedMatchesRequest(param, (res) => { if (res) setEvent(res) }));
                else if (location.pathname === "/cup-winner")
                    dispatch(getInPlayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
                else {
                    navigate('/inplay');
                }
            } else {
                navigate(-1);
            }
        }

    }, [selectedSportId]);

    const gotoDetail = (event_id) => {
        if (currentUser.username) {
            navigate(`/match-detail/${event_id}`)
        } else {
            // navigate(`/match-detail/${event_id}`)
        }
    }

    const pinUnpin = (event_id) => {
        let newMarketList = Object.keys(Events.records).map((sport, i) => {
            Events.records[sport].map((item, idx) => {
                if (item.event_id === event_id) {
                    let data = {
                        event_id: event_id,
                        status: item.pin_status ? item.pin_status == 0 ? 1 : 0 : 1
                    }
                    pinMatch(data)
                    item.pin_status = item.pin_status ? item.pin_status == 0 ? 1 : 0 : 1
                }
                // return item
            })
            return Events.records[sport]
        })
        setEvent((s) => ({ ...s, ...newMarketList }));
    }
    return (
        <Fragment>
            <div className="midLayout h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar">
                <div className="flex anvMarquee  h-[28px] md:h-7 items-center md:my-1 md:rounded overflow-hidden">
                    <img className="h-4 px-1" src={Images.INFO.default} />
                    <marquee
                        className="text-[12px] flex items-center font-semibold "
                        behavior=""
                        direction=""
                    >
                        {GlobalAnnouncement != null ? GlobalAnnouncement.client_msg : `Welcome to Velocity999 !!`}
                    </marquee>
                </div>
                <SportTabs />
                <div className="dark:bg-black min-h-[600px]">
                    <div className="pt-3">
                        <div className="max-w-[1409px] mx-auto ">
                            {isLoader ?
                                <div className="flex justify-center items-start text-center py-12  h-screen">
                                    <div className="px-24 py-8 bg-[#F5F5F5] dark:bg-[#22262A] flex justify-center items-center">
                                        <DotLoader color="#f2d61d" />
                                    </div>
                                </div>
                                : Events && Events.total_records && Events.total_records > 0 ?
                                    <Fragment>
                                        {Events ?
                                            <Fragment>
                                                {(selectedSportId.sports_id === 999 || selectedSportId.sports_id === 777) ?
                                                    <MatkaMatchList eventList={Events?.records ? Events?.records : []} />
                                                    :
                                                    <FixtureListView
                                                        EventDetails={Events}
                                                        location={location}
                                                        selectedSportTab={selectedSportTab}
                                                        pinUnpin={pinUnpin}
                                                        currentUser={currentUser} />
                                                }
                                            </Fragment>
                                            :
                                            <div className="text-center dark:text-white py-12">
                                                <h4 >Coming soon...</h4>
                                            </div>
                                        }
                                    </Fragment>
                                    : Number(Events.total_records) === 0 ?
                                        <div className="text-center text-red-500 py-12">
                                            <h2>No Matches Found !!!</h2>
                                        </div> : null
                            }

                          
                            <div className="lg:hidden" >
                                <br />
                                <CasinoMarket />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
            <div className="w-[368px] xl:block hidden h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar">
                <CasinoSidebar />
            </div>
        </Fragment>
    )
}

export default MatchesList