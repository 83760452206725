import React, { Fragment, useEffect, useState } from "react";
import { Images } from "App/constants";
import { useDispatch, useSelector } from "react-redux";
import { depositPaymentRequest, paymentInfoRequest } from "App/_redux/_actions";
import { toast } from "react-hot-toast";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { depositListRequest, getBonusOffers, instantPayRequest } from "App/_redux/_services";
import { DotLoader } from 'react-spinners';
import CoustomTabel from "../../components/Common/CoustomTabel";
import { getDateAndTime } from "App/utils";
import { QRCode } from "react-qrcode-logo";
import YouTube from "react-youtube";
import imageCompression from 'browser-image-compression';

const Deposit = () => {
    const { GlobalAnnouncement } = useSelector((state) => state.match);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [paymentFilter, setPaymentFilter] = useState({
        type: 'BANK'
    })
    const [paymentInfo, setPaymentInfo] = useState([])
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [amount, setAmount] = useState('');
    const [utr_no, setUTR] = useState('');
    const [clipboard, setClipboard] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingDepositList, setDepositLoading] = useState(false)
    const [bonusOffers, setBonusOffers] = useState(false)
    const [depositList, setDepositList] = useState(false)

    const instantAmount = [
        { 'amount': 300 },
        { 'amount': 500 },
        { 'amount': 1000 },
        { 'amount': 2000 },
        { 'amount': 5000 },
        { 'amount': 10000 },
    ]

    const handleChange = async (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        // setFile(e.target.files[0]);
        const file = e.target.files[0];
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(file, options);
            setFile(compressedFile);
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        dispatch(paymentInfoRequest({}, (res) => {
            if (res) {
                setPaymentInfo(res)
            }
        }))
        bonusOfferRequest()
        getDepositRequest()
    }, [])

    const bonusOfferRequest = async () => {
        try {
            await getBonusOffers().then((res) => {
                if (res.data && res.data != "") {
                    setBonusOffers(res.data)
                }
            })
        } catch (error) {
        }
    }
    const getDepositRequest = async () => {
        setDepositLoading(true)
        try {
            await depositListRequest().then((res) => {
                setDepositLoading(false)
                if (res.data && res.data != "") {
                    setDepositList(res.data)

                }
            })
        } catch (error) {
            setDepositLoading(false)
        }
    }

    const clearState = () => {
        setAmount('')
        setImage(null)
        setFile(null)
        setUTR('')
        setClipboard(false)
    }

    const onSubmit = () => {
        setLoading(true)
        let mode = paymentFilter.type == "BANK" ? 1
            : paymentFilter.type == "Paytm" ? 2
                : paymentFilter.type == "Gpay" ? 3
                    : paymentFilter.type == "PhonePe" ? 4
                        : paymentFilter.type == "UPI" ? 5
                            : null

        let param = new FormData();
        param.append("amount", amount);
        param.append("utr_no", utr_no);
        param.append("mode", mode);
        param.append("screenshot", file);

        if (amount < 10) {
            setLoading(false)
            toast.error("Amount should be minimum 10")
            return
        }
        else if (!utr_no.length) {
            setLoading(false)
            toast.error("Please Enter UTR Number")
            return
        } else if (file == null) {
            setLoading(false)
            toast.error("Please select screenshot")
            return
        }

        try {
            dispatch(depositPaymentRequest(param, (res) => {
                setLoading(false)
                if (res && res.error != "") {
                    toast.error(res.error)
                    return
                }
                if (res && res.message) {
                    clearState()
                    toast.success(res.message)
                    getDepositRequest()
                }
            }))
        } catch (error) {
            setLoading(false)
            toast.error("something went wrong.")
        }
    }

    const handleInstantPayment = async () => {
        const data = {
            amount: amount
        }
        setLoading(true)
        try {
            let res = await instantPayRequest(data).then((response) => {
                if (response.data) {
                    setLoading(false)
                    navigate(`/instant-pay/${amount}/${response.data.merchantTransactionId}`, { state: response.data })
                }
                if (response.error) {
                    setLoading(false)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response.error,
                        color: 'red',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: "red",
                    });
                }
            })

        } catch (error) {
            setLoading(false)
            if (error.error != "") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.error,
                    color: 'red',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: "red",
                });
            }
        }

    }

    const columnData = [
        { name: "Date", value: "created_at" },
        { name: "Payment Mode", value: "payment_mode" },
        { name: "UTR No", value: "utr_no" },
        { name: "Mobile", value: "mobile" },
        { name: "Amount", value: "amount" },
        { name: "status", value: "status" },
        { name: "Remark", value: "remark" },
    ];

    const columnDataList = (data) => {
        return data && data?.length > 0
            ? data?.map((item) => ({
                ...item,
                status: item.status == 0 ? 'Pending' : item.status == 1 ? "Completed" : item.status == 2 ? "Rejected" : item.status == 4 ? "In Progress" : "",
                created_at: getDateAndTime("MMMM DD, hh:mm A", item?.created_at),
            }))
            : [];
    };

    return (
        <Fragment>
            <div className="innrRight_singleBx">
                <div className="st_featured_thumb">
                    <div className="text_wrper">
                        <div class="st_hd1">
                            {/* <h4 className="h4_semiTxt py-[10px]">Deposit</h4> */}
                            <h4 className="h4_semiTxt py-[10px] px-2 flex items-center gap-2"><AiOutlineArrowLeft className=' cursor-pointer' onClick={() => navigate(-1)} /> Deposit</h4>
                        </div>
                        <div className="mainDepos_bx">
                            <div className="payMent_modeBx">
                                <ul>


                                    {paymentInfo && <Fragment>
                                        {Object.keys(paymentInfo).map((item, index) => (
                                            <Fragment>
                                                {paymentInfo[item].status != 0 && <li>
                                                    <button onClick={() => { setPaymentFilter((s) => ({ ...s, type: item })); clearState() }} className={paymentFilter?.type === item && 'activeTab'}>
                                                        <img src={item == "BANK" ? Images.BANKTRANSFER : item == "Paytm" ? Images.PAYTM : item == "Gpay" ? Images.GPAY : item == "PhonePe" ? Images.PHONEPE : item == "UPI" ? Images.UPI : null} alt="" />
                                                        <span>{item}</span>
                                                    </button>
                                                </li>}
                                            </Fragment>
                                        ))}
                                    </Fragment>}
                                </ul>
                            </div>
                            <div className="inrPay_bx">
                                <div className="contents_bx">
                                    {Object.keys(paymentInfo).map((item, index) => (
                                        <Fragment>
                                            {(item == "BANK" && paymentFilter?.type === 'BANK') ?
                                                <div>
                                                    <h5 className="text-sm font-semibold text-[#000] dark:text-[#fff] mb-[10px]">Transfer Details</h5>
                                                    <div className="flex flex-wrap justify-between">
                                                        <div className="bb_transfer_subsection">
                                                            <div className="bb_tran_subdiv">
                                                                <p className="bb_bank_name">Bank Name</p>
                                                                <h4 className="bb_tran_subdiv_sec">{paymentInfo[item].bank_name}</h4>
                                                            </div>

                                                            <div className="bb_copy_txt">
                                                                <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].bank_name); setClipboard(paymentInfo[item].bank_name) }}>{clipboard && clipboard == paymentInfo[item].bank_name ? "Copied" : "Copy"}</button>
                                                            </div>
                                                        </div>

                                                        <div className="bb_transfer_subsection">
                                                            <div className="bb_tran_subdiv">
                                                                <p className="bb_bank_name">Account Holder Name</p>
                                                                <h4 className="bb_tran_subdiv_sec">{paymentInfo[item].account_holder}</h4>
                                                            </div>

                                                            <div className="bb_copy_txt">
                                                                <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].account_holder); setClipboard(paymentInfo[item].account_holder) }}>{clipboard && clipboard == paymentInfo[item].account_holder ? "Copied" : "Copy"}</button>
                                                            </div>
                                                        </div>

                                                        <div className="bb_transfer_subsection">
                                                            <div className="bb_tran_subdiv">
                                                                <p className="bb_bank_name">Account Number</p>
                                                                <h4 className="bb_tran_subdiv_sec">{paymentInfo[item].account_number}</h4>
                                                            </div>

                                                            <div className="bb_copy_txt">
                                                                <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].account_number); setClipboard(paymentInfo[item].account_number) }}>{clipboard && clipboard == paymentInfo[item].account_number ? "Copied" : "Copy"}</button>
                                                            </div>
                                                        </div>

                                                        <div className="bb_transfer_subsection">
                                                            <div className="bb_tran_subdiv">
                                                                <p className="bb_bank_name">Account Type</p>
                                                                <h4 className="bb_tran_subdiv_sec">{paymentInfo[item].account_type}</h4>
                                                            </div>

                                                            <div className="bb_copy_txt">
                                                                <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].account_type); setClipboard(paymentInfo[item].account_type) }}>{clipboard && clipboard == paymentInfo[item].account_type ? "Copied" : "Copy"}</button>
                                                            </div>
                                                        </div>

                                                        <div className="bb_transfer_subsection">
                                                            <div className="bb_tran_subdiv">
                                                                <p className="bb_bank_name">IFSC Code</p>
                                                                <h4 className="bb_tran_subdiv_sec">{paymentInfo[item].IFSC}</h4>
                                                            </div>

                                                            <div className="bb_copy_txt">
                                                                <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].IFSC); setClipboard(paymentInfo[item].IFSC) }}>{clipboard && clipboard == paymentInfo[item].IFSC ? "Copied" : "Copy"}</button>
                                                            </div>
                                                        </div>

                                                        <div className="bb_transfer_subsection">
                                                            <div className="bb_tran_subdiv">
                                                                <p className="bb_bank_name">Branch</p>
                                                                <h4 className="bb_tran_subdiv_sec">BRANCH</h4>
                                                            </div>

                                                            <div className="bb_copy_txt">
                                                                <button className="click_btn" onClick={() => { navigator.clipboard.writeText('BRANCH'); setClipboard('BRANCH') }}>{clipboard && clipboard == "BRANCH" ? "Copied" : "Copy"}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : (item == "Paytm" && paymentFilter?.type === 'Paytm') ?
                                                    <div className="mb-3">
                                                        <h5 className="text-sm font-semibold text-[#000] dark:text-[#fff] mb-[10px]">Wallet</h5>

                                                        <div className="flex flex-col flex-wrap justify-between">
                                                            <div className="bb_transfer_subsection amtFiller mb-[6px]">
                                                                <div className="bb_tran_subdiv ">
                                                                    <p className="bb_bank_name text-[13px] opacity-90 font-medium">{paymentInfo[item].paytm_id}</p>
                                                                </div>

                                                                <div className="bb_copy_txt">
                                                                    <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].paytm_id); setClipboard(paymentInfo[item].paytm_id) }}>{clipboard && clipboard == paymentInfo[item].paytm_id ? "Copied" : "Copy"}</button>
                                                                </div>
                                                            </div>
                                                            <h4 className="bb_tran_subdiv_sec ml-1">Wallet Name: {paymentInfo[item].name}</h4>
                                                        </div>

                                                        <QRCode
                                                            value={`upi://pay?pn=${paymentInfo[item].name}&pa=${paymentInfo[item].paytm_id}&cu=INR`}
                                                            size="300"
                                                            logoImage={'/FAV-icon.png'}
                                                            logoWidth="80"
                                                            logoHeight="100"
                                                            logoOpacity="0.6"
                                                        />
                                                    </div>
                                                    : (item === 'Gpay' && paymentFilter?.type === 'Gpay') ?
                                                        <div className="mb-3">
                                                            <h5 className="text-sm font-semibold text-[#000] dark:text-[#fff] mb-[10px]">Wallet</h5>

                                                            <div className="flex flex-col flex-wrap justify-between">
                                                                <div className="bb_transfer_subsection amtFiller mb-[6px]">
                                                                    <div className="bb_tran_subdiv ">
                                                                        <p className="bb_bank_name text-[13px] opacity-90 font-medium">{paymentInfo[item].gpay_id}</p>
                                                                    </div>

                                                                    <div className="bb_copy_txt">
                                                                        <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].gpay_id); setClipboard(paymentInfo[item].gpay_id) }}>{clipboard && clipboard == paymentInfo[item].gpay_id ? "Copied" : "Copy"}</button>
                                                                    </div>
                                                                </div>
                                                                <h4 className="bb_tran_subdiv_sec ml-1">Wallet Name: {paymentInfo[item].name}</h4>
                                                            </div>
                                                            <QRCode
                                                                value={`upi://pay?pn=${paymentInfo[item].name}&pa=${paymentInfo[item].gpay_id}&cu=INR`}
                                                                size="300"
                                                                logoImage={'/FAV-icon.png'}
                                                                logoWidth="80"
                                                                logoHeight="100"
                                                                logoOpacity="0.6"
                                                            />
                                                        </div>
                                                        : (item === 'PhonePe' && paymentFilter?.type === 'PhonePe') ?
                                                            <div className="mb-3">
                                                                <h5 className="text-sm font-semibold text-[#000] dark:text-[#fff] mb-[10px]">Wallet</h5>

                                                                <div className="flex flex-col flex-wrap justify-between">
                                                                    <div className="bb_transfer_subsection amtFiller mb-[6px]">
                                                                        <div className="bb_tran_subdiv ">
                                                                            <p className="bb_bank_name text-[13px] opacity-90 font-medium">{paymentInfo[item].phonepe_id}</p>
                                                                        </div>

                                                                        <div className="bb_copy_txt">
                                                                            <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].phonepe_id); setClipboard(paymentInfo[item].phonepe_id) }}>{clipboard && clipboard == paymentInfo[item].phonepe_id ? "Copied" : "Copy"}</button>
                                                                        </div>
                                                                    </div>
                                                                    <h4 className="bb_tran_subdiv_sec ml-1">Wallet Name: {paymentInfo[item].name}</h4>
                                                                </div>
                                                                <QRCode
                                                                    value={`upi://pay?pn=${paymentInfo[item].name}&pa=${paymentInfo[item].phonepe_id}&cu=INR`}
                                                                    size="300"
                                                                    logoImage={'/FAV-icon.png'}
                                                                    logoWidth="80"
                                                                    logoHeight="100"
                                                                    logoOpacity="0.6"
                                                                />
                                                            </div>
                                                            : (item === 'UPI' && paymentFilter?.type === 'UPI') ?
                                                                <div className="mb-3">
                                                                    <h5 className="text-sm font-semibold text-[#000] dark:text-[#fff] mb-[10px]">Wallet</h5>

                                                                    <div className="flex flex-col flex-wrap justify-between">
                                                                        <div className="bb_transfer_subsection amtFiller mb-[6px]">
                                                                            <div className="bb_tran_subdiv ">
                                                                                <p className="bb_bank_name text-[13px] opacity-90 font-medium">{paymentInfo[item].upi_id}</p>
                                                                            </div>

                                                                            <div className="bb_copy_txt">
                                                                                <button className="click_btn" onClick={() => { navigator.clipboard.writeText(paymentInfo[item].upi_id); setClipboard(paymentInfo[item].upi_id) }}>{clipboard && clipboard == paymentInfo[item].upi_id ? "Copied" : "Copy"}</button>
                                                                            </div>
                                                                        </div>
                                                                        <h4 className="bb_tran_subdiv_sec ml-1">Wallet Name: {paymentInfo[item].name}</h4>
                                                                    </div>
                                                                    <QRCode
                                                                        value={`upi://pay?pn=${paymentInfo[item].name}&pa=${paymentInfo[item].upi_id}&cu=INR`}
                                                                        size="300"
                                                                        logoImage={'/FAV-icon.png'}
                                                                        logoWidth="80"
                                                                        logoHeight="100"
                                                                        logoOpacity="0.6"
                                                                    />
                                                                </div>
                                                                : null}
                                        </Fragment>
                                    ))}
                                    <div>
                                        <h5 className="text-sm font-semibold text-[#000] dark:text-[#fff] mb-[10px]">Your Payment Details</h5>
                                        <div className="amtSelector_bx">
                                            {instantAmount.map((item, key) => (
                                                <button className="amtSelector_btn" {...key} onClick={() => { setAmount(item.amount) }}>{item.amount}</button>
                                            ))}

                                        </div>
                                        <div className="relative amtFiller">
                                            <label htmlFor="" className="bb_bank_name absolute left-[10px] top-1">Amount</label>
                                            <input className="form-control placeholder:text-[#000] dark:placeholder:text-[#b4b4b4]"
                                                placeholder="Enter amount"
                                                type="tel"
                                                name="amount"
                                                id="amount"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {paymentFilter?.type != "Instant Transfer"
                                        &&
                                        <Fragment>
                                            <div className="relative amtFiller mt-2">
                                                <label htmlFor="" className="bb_bank_name absolute left-[10px] top-1">UTR No</label>
                                                <input className="form-control placeholder:text-[#000] dark:placeholder:text-[#b4b4b4]"
                                                    placeholder="Enter UTR Number"
                                                    type="text"
                                                    name="utr_no"
                                                    id="utr_no"
                                                    value={utr_no}
                                                    onChange={(e) => setUTR(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (/[^0-9a-zA-Z]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className=" relative mt-3">
                                                <div id="userimageold1" bis_skin_checked="1">
                                                    <img id="image_upload5" width="100px" height="100px" />
                                                </div>
                                                <input class="image_upload5"
                                                    type="file"
                                                    name="bank_image[]"
                                                    id="bank_image"
                                                    placeholder=" "
                                                    accept="image/png,image/jpg, image/jpeg"
                                                    onChange={handleChange}
                                                />
                                                <div className="dangerTxt">*  Allowed file type JPG,JPEG,PNG</div>
                                            </div>
                                            {image && <img src={image} width={200} height={300} />}
                                        </Fragment>
                                    }

                                    <div className="mt-6">
                                        <div className="max-w-[100px] m-[0_0_1em] w-full">
                                            {paymentFilter?.type === "Instant Transfer" ?
                                                <button className="_btnSave font-medium">
                                                    {amount >= 100 ?
                                                        // <a href={`javascript:window.open('/instant-pay/${amount}','_blank','height=600,width=800, resizable=0, left=300, location=no');`}>Continue</a> 
                                                        // <Link to={`/instant-pay/${amount}`}>Continue</Link>
                                                        <div onClick={() => handleInstantPayment()}>Continue</div>
                                                        : <p onClick={() => { toast.error('Minimum Amount should be 100') }}>Continue</p>}
                                                </button>
                                                :
                                                <button className="_btnSave font-medium" disabled={loading} onClick={() => onSubmit()}>SUBMIT</button>}
                                        </div>

                                        {bonusOffers && bonusOffers.length > 0 && paymentFilter?.type === "Instant Transfer" && <div className="disCount_bx">
                                            <h4 className="text-base text-[#000] font-normal mb-2">Offers on Deposit</h4>
                                            <div className="flex flex-wrap gap-x-[10px] gap-y-[15px]">

                                                {bonusOffers.map((bonus, idx) => (
                                                    <div className="rounded overflow-hidden border-[1px] dark:bg-[#22262a] border-[#C2C2C2] dark:border-[#222] flex-[0_0_calc(50%-5px)] sm:flex-[0_0_133px]">
                                                        <h5 className="text-sm font-normal capitalize bg-[#000] dark:bg-[#f2d61d] text-[#fff] dark:text-[#000] text-center p-[4px_2px]">On {bonus.min_amount} Deposit</h5>
                                                        <div className="text-center p-[7px_10px_7px]">
                                                            <h6 className="text-[#000] dark:text-[#fff] text-sm capitalize">get {bonus.bonus} %</h6>
                                                            <p className="text-[#000] dark:text-[#fff] text-sm capitalize">Bonus Cash</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>}

                                        <p className="text-sm text-[#101010] dark:text-[#fff]">Need help? please check the how to deposit guide or contact us on live chat / email now.</p>
                                    </div>

                                    {GlobalAnnouncement && GlobalAnnouncement.how_to_deposit &&
                                        <div className="w-full">
                                            <YouTube videoId={GlobalAnnouncement.how_to_deposit}
                                                iframeClassName={'w-full'}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {loadingDepositList ?
                    <div className="flex justify-center items-start text-center py-12">
                        <div className="px-24 py-8 bg-[#F5F5F5] dark:bg-[#22262A] flex justify-center items-center">
                            <DotLoader color="#f2d61d" />
                        </div>
                    </div>
                    :
                    <div className="table_controler">
                        <CoustomTabel
                            columnData={columnData}
                            dataList={columnDataList(depositList)}
                        />
                    </div>}
            </div>
            {loading &&
                <div className="fixed top-0 left-0 z-[300] flex justify-center items-center text-center py-12  w-full h-full backDrop backdrop-blur-md">
                    <div className="px-24 py-8 flex justify-center items-center">
                        <DotLoader color="#f2d61d" />
                    </div>
                </div>}
        </Fragment>
    );
};

export default Deposit