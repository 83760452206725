import { saveBankDetail, userBankDetail, withDrawListRequest } from 'App/_redux/_services';
import React, { useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BankAccount = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({
        bank_name: '',
        acc_name: '',
        acc_number: '',
        ifsc: '',
        account_type: '',
        branch: ''

    })

    const [errors, setError] = useState(null)

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState((s) => ({ ...s, [name]: value }));
        setError((s) => ({ ...s, [name]: null }));

    };

    const handleKeyDown = (e) => {
        if (/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }


    useEffect(() => {
        fetchAccountDetails();
    }, [])

    const fetchAccountDetails = async () => {
        try {
            const res = await userBankDetail().then((response) => {
                if (response.data != '') {
                    let obj = response.data
                    setState((s) => ({ ...s, ...obj }));
                }
            })
        } catch (error) {
            if (error.error != '') {
                toast.error("Something went wrong.")
            }
        }
    }

    const saveDetails = async () => {
        try {
            const res = await saveBankDetail(state).then((response) => {
                if (response.message != '') {
                    toast.success(response.message)
                    fetchAccountDetails()
                }
            })
        } catch (error) {
            if (error.error != '') {
                toast.error("Something went wrong.")
                let obj = error.error
                setError((s) => ({ ...s, ...obj }));
            }

        }
    }

    return (
        <div className="innrRight_singleBx">
            <div className="bg-[#F8F8F8] dark:bg-[#22262A] p-[10px_18px_10px_18px] xl:p-[10px_20px_10px_30px] rounded">
                <h4 className="h4_semiTxt flex items-center gap-2"><AiOutlineArrowLeft className=' cursor-pointer' onClick={() => navigate(-1)} /> Bank Account</h4>

                <div className="filter_box filter_boxGrid">


                    <div className="filterFx_basis ">
                        <input type="text"
                            name="bank_name" id=""
                            placeholder='Bank Name'
                            value={state.bank_name}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className="cstFrm_inputs form-control" />
                        {errors && errors.bank_name && <small className='text-red-500'>{errors.bank_name.replace('_', ' ')}</small>}
                    </div>
                    <div className="filterFx_basis ">
                        <input type="text"
                            name="acc_name" id=""
                            placeholder='Account Holder Name'
                            value={state.acc_name}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className="cstFrm_inputs form-control" />
                        {errors && errors.acc_name && <small className='text-red-500'>{errors.acc_name.replace('_', ' ')}</small>}
                    </div>

                    <div className="filterFx_basis ">
                        <input type="number"
                            name="acc_number" id=""
                            placeholder='Account Number'
                            value={state.acc_number}
                            onChange={handleChange}
                            className="cstFrm_inputs form-control" />
                        {errors && errors.acc_number && <small className='text-red-500'>{errors.acc_number.replace('_', ' ')}</small>}
                    </div>

                    <div className="filterFx_basis ">
                        <input type="text"
                            name="ifsc" id=""
                            placeholder='IFSC Code'
                            value={state.ifsc}
                            onChange={handleChange}
                            className="cstFrm_inputs form-control" />
                        {errors && errors.ifsc && <small className='text-red-500'>{errors.ifsc}</small>}
                    </div>

                    {/* <div className="filterFx_basis ">
                        <input type="text"
                            name="account_type" id=""
                            placeholder='Account Type'
                            value={state.account_type}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className="cstFrm_inputs form-control" />
                        {errors && errors.account_type && <small className='text-red-500'>{errors.account_type.replace('_', ' ')}</small>}
                    </div> */}

                    <div className="filterFx_basis " >
                        <div className="mainDate_picBx" >
                            <select className="cstFrm_inputs form-control" name="account_type" onChange={handleChange} value={state.account_type}>
                                <option selected disabled>Account Type</option>
                                <option value="Saving">Saving</option>
                                <option value="Current">Current</option>
                            </select>
                            <BsChevronDown className="anvChevron" />
                        </div>
                        {errors && errors.account_type && <small className='text-red-500'>{errors.account_type.replace('_', ' ')}</small>}
                    </div>
                    <div className="filterFx_basis ">
                        <input type="text"
                            name="branch" id=""
                            placeholder='Branch'
                            value={state.branch}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className="cstFrm_inputs form-control" />
                        {errors && errors.branch && <small className='text-red-500'>{errors.branch}</small>}
                    </div>

                    <div className="d-flxe gap-1 filterFx_basis filterFx_basis_full" >
                        <button type="button" className="_btnSave h-full" onClick={() => saveDetails()}>Save details</button>
                    </div>
                </div>
            </div>

            <div className="table_controler">
                {/* <CustomTable /> */}
            </div>

        </div>
    );
}
export default BankAccount