import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from 'react-router-dom'

import Header from "App/components/Layout/Header";
import { CustomContext } from "App/helper/CustomContext";
import { getStorage } from "App/helper/storage";
import Footer from "App/components/Layout/Footer";
import { SportTabs, Sidebar, BottomNavBar } from "App/components";
import { Images } from "App/constants";
import { useScreenDetector } from "App/hooks/useScreenDetector";
import { useSelector } from "react-redux";
import { LoginModal } from "App/modals";
import RegisterModal from "App/modals/RegisterModal";
import images from "App/constants/images";

const UserLayout = () => {
    const { authModal } = useSelector(state => state.auth)
    const { GlobalAnnouncement } = useSelector(state => state.match)
    const { isMobile } = useScreenDetector();
    const { pathname } = useLocation();
    const [isMode, setIsMode] = useState(false);
    useEffect(() => {
        let mode = localStorage.getItem('theme')
        document.body.classList.add(mode);
        setIsMode(true)
    });

    const [topbarIsOpen, setTopbarOpen] = useState(false);
    const [showKycModal, setShowKycModal] = useState(false);
    const [profileData, setProfileData] = useState(
        getStorage("logged-in")?.result
    );

    return (
        <CustomContext.ModalContext.Provider value={{ showKycModal, setShowKycModal }} >
            <CustomContext.ProfileData.Provider value={{ profileData, setProfileData }} >
                <div className={isMobile ? "h-[calc(100%_-_16rem)]" : ""}>
                    <Header toggleTopbar={() => setTopbarOpen(!topbarIsOpen)} topbarIsOpen={topbarIsOpen} />
                    <div className="mainContent_bx ">
                        <div className="bg-[#ebebeb]  dark:bg-[#0B0D0E] ">
                            <div className="flex">
                                <div className="outerSide_bar "><Sidebar /></div>
                                <div className="w-full lg:w-[calc(100%-228px)]">
                                    <div className="rightNside">
                                        <Outlet />
                                    </div>
                                    {(!isMobile && (pathname != '/inplay' && pathname != '/favorite' && pathname != '/casino' && pathname != '/casino-market' && pathname != '/login' && pathname != '/in-play' && pathname != '/all-sports')) && <Footer />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile && <BottomNavBar />}
                {authModal == "LOGIN" ? <LoginModal />
                    : authModal == "REGISTER" ? <RegisterModal /> : null}

                {GlobalAnnouncement && GlobalAnnouncement.whatsapp_number && <div className="fixed z-50 right-2 bottom-[70px] md:bottom-3 animate-bounce">
                    <a href={`https://wa.me/+91${GlobalAnnouncement.whatsapp_number}?text=hii`} target="_blank">
                        <img src={images.WHATSAPP_ICON} className="h-10 w-10 md:h-16 md:w-16" />
                    </a>
                </div>}
            </CustomContext.ProfileData.Provider>
        </CustomContext.ModalContext.Provider>
    )
}

export default UserLayout