import { Images } from "App/constants";

export const constant = {
  sportTypeList: [
    { name: "Cricket", count: "6", icon: "cricket-ball_1.svg", sport_id: 4 },
    { name: "Soccer", count: "4", icon: "soccer_1.svg", sport_id: 1 },
    { name: "Tennis", count: "1", icon: "tennis-ball_2.svg", sport_id: 3 },
    { name: "Kabaddi", count: "3", icon: "kabbadi_1.svg", sport_id: 11 },
    { name: "Politics", count: "5", icon: "politics_1.svg", sport_id: 8 },
    { name: "Horse racing", count: "2", icon: "horse_1.svg", sport_id: 9 },
    { name: "Matka", count: "7", icon: "horse_1.svg", sport_id: 999 },
  ],
  marketType: [
    { title: "All", count: "6", icon: Images.ALL.default, id: 1, link: '/all-sports', type: 10 },
    { title: "In Play", count: "6", icon: Images.CLOCK.default, id: 2, link: '/inplay', type: 1 },
    { title: "Up Coming", count: "3", icon: Images.CALENDER.default, id: 3, link: '/upcoming', type: 2 },
    { title: "Live Position", count: "2", icon: Images.POSITION.default, id: 4, link: '/live-position', type: 4 },
    { title: "Favorite", count: "5", icon: Images.PIN_MATCH.default, id: 5, link: '/favorite', type: 3 },
    // { title: "Cup Winner", count: "4", icon: Images.CUP_ICON.default, id: 6, link: '/cup-winner', type: 5 },
    { title: "Casino", count: "2", icon: Images.CASINO_ICON.default, id: 7, link: '/casino', type: 1 },
  ],
  menuItems: [
    { name: "Account Statement", link: "/statement" },
    { name: "Profit & Loss", link: "/profit-loss" },
    { name: "Bet History", link: "/bet-history" },
    { name: "Bonus", link: "/bonus" },
    { name: "Withdrawal", link: "/withdrawal" },
    { name: "Odd Settings", link: "/setting" },
    { name: "Change Password", link: "/change-password" },
    { name: "Bank Account", link: "/bank-account" },
  ]
};
