import { matchConstants } from "App/_redux/_constants";
import { getStorage } from "App/utils";

let initialState = {
  sportList: [],
  selectedSportId: { sports_id: "", sports_name: 'All' },
  selectedSportTab: { title: 'All', count: '6', icon: 'cloack_icon.svg', id: 1, link: '/all-sports' },
  inFixtureList: [],
  upFixtureList: [],
  pinnedMatchesList: [],
  userBalance: null,
  isLoader: false,
  gamesList: [],
  sportsLeagues: [],
  masterData: null,
  GlobalAnnouncement: null,
  bannerList: null,
  casinoBannerList: null,
};

export function match(state = initialState, action) {
  switch (action.type) {
    case matchConstants.GET_BANNERS_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_BANNERS_SUCCESS:
      return {
        ...state,
        bannerList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_CASINO_BANNERS_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_CASINO_BANNERS_SUCCESS:
      return {
        ...state,
        casinoBannerList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_CLIENT_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_CLIENT_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        GlobalAnnouncement: action.data,
        isLoader: false,
      };
    case matchConstants.GET_MASTER_DATA_REQUEST:
      return {
        ...state,
      };
    case matchConstants.GET_MASTER_DATA_SUCCESS:
      return {
        ...state,
        masterData: action.data,
        isLoader: false,
      };
    case matchConstants.GET_GAMES_LIST_REQUEST:
      return {
        ...state,
      };
    case matchConstants.GET_GAMES_LIST_SUCCESS:
      return {
        ...state,
        gamesList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_SPORTS_LEAGUES_REQUEST:
      return {
        ...state,
      };
    case matchConstants.GET_SPORTS_LEAGUES_SUCCESS:
      return {
        ...state,
        sportsLeagues: action.data,
        isLoader: false,
      };
    case matchConstants.GET_USER_BALANCE_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        userBalance: action.data,
        isLoader: false,
      };
    case matchConstants.GET_ALL_SPORT_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_ALL_SPORT_SUCCESS:
      return {
        ...state,
        sportList: action.data,
        isLoader: false,
      };

    case matchConstants.GET_SELECTED_SPORT_TAB:
      return {
        ...state,
        selectedSportTab: action.data,
      };
    case matchConstants.GET_SELECTED_SPORT:
      return {
        ...state,
        selectedSportId: action.data,
      };


    case matchConstants.GET_INPLAAY_FIXTURE_REQUEST:
      return {
        ...state,
        inFixtureList: { total_records: 0, records: [] },
        isLoader: true,
      };
    case matchConstants.GET_INPLAAY_FIXTURE_SUCCESS:
      return {
        ...state,
        inFixtureList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_UPCOMING_FIXTURE_REQUEST:
      return {
        ...state,
        upFixtureList: { total_records: 0, records: [] },
        isLoader: true,
      };
    case matchConstants.GET_UPCOMING_FIXTURE_SUCCESS:
      return {
        ...state,
        upFixtureList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_PINNED_MATCHES_REQUEST:
      return {
        ...state,
        pinnedMatchesList: { total_records: 0, records: [] },
        isLoader: true,
      };
    case matchConstants.GET_PINNED_MATCHES_SUCCESS:
      return {
        ...state,
        pinnedMatchesList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_MATCH_DETAIL_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_MATCH_DETAIL_SUCCESS:
      return {
        ...state,
        matchDetail: action.data,
        isLoader: false,
      };
    case matchConstants.GET_CLIENT_ANNU_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_CLIENT_ANNU_SUCCESS:
      return {
        ...state,
        clientAnnouncement: action.data,
        isLoader: false,
      };
    case matchConstants.SET_BET_SUCCESS:
      return {
        ...state,
        betPriceDetail: action.data,
        isLoader: false,
      };

    case matchConstants.CLEAR_BET_SUCCESS:
      return {
        ...state,
        betPriceDetail: action.data,
        isLoader: false,
      };
    case matchConstants.FAILED_RESPONSE:
      return {
        ...state,
        isLoader: false,
      };
    default:
      return state;
  }
}
