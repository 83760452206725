import React, { useEffect, useState } from "react";
import CoustomTabel from "../../components/Common/CoustomTabel";
import { getLedger } from "App/_redux/_services";
import { getDateAndTime } from "App/utils";
import { DotLoader } from "react-spinners";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

export const ProfitLoss = () => {
  const navigate = useNavigate()
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDataList();
  }, []);

  const getDataList = async () => {
    try {
      setLoading(true)
      const res = await getLedger();
      if (res?.data?.ledger) {
        setDataList(res?.data?.ledger);
        setLoading(false)
      } else {
        setDataList({ total_count: 0, list: [] });
        setLoading(false)
      }
    } catch (error) { }
  };

  const columnData = [
    { name: "Date", value: "created_at" },
    { name: "Entry", value: "remark" },
    { name: "Debit", value: "debit_amount" },
    { name: "Credit", value: "credit_amount" },
    { name: "Balance", value: "ledger_balance" },
  ];

  const columnDataList = (data) => {
    return data && data?.length > 0
      ? data?.map((item) => ({
        ...item,
        created_at: getDateAndTime("MMMM DD, hh:mm A", item?.created_at),
      }))
      : [];
  };

  return (
    <div className="innrRight_singleBx">
      <div className="bg-[#F8F8F8] dark:bg-[#22262A] p-[10px_18px_10px_18px] xl:p-[10px_20px_10px_30px] rounded">
        <div className="statementFlx ">
          <div>
            <h4 className="h4_semiTxt flex items-center gap-2"> <AiOutlineArrowLeft className=' cursor-pointer' onClick={() => navigate(-1)} /> Profit Loss Statement</h4>
          </div>
        </div>
      </div>

      {loading ?
        <div className="flex justify-center items-start text-center py-12">
          <div className="px-24 py-8 bg-[#F5F5F5] dark:bg-[#22262A] flex justify-center items-center">
            <DotLoader color="#f2d61d" />
          </div>
        </div>
        :
        <div className="table_controler">
          <CoustomTabel
            columnData={columnData}
            dataList={columnDataList(dataList)}
          />
        </div>}
    </div>
  );
};
