import React, { Fragment, useEffect, useState } from 'react'
import { menuitem } from './menu'
import { useDispatch, useSelector } from 'react-redux'
import { getAllGamesRequest, getLeagueEventRequest, getSportsLeaguesRequest } from 'App/_redux/_actions'
import { Link, NavLink } from 'react-router-dom'
import { Images } from 'App/constants'
import images from 'App/constants/images'
import API from "App/lib/api-urls"
import axios from 'axios'
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';
import _ from "App/helper/_";


const Sidebar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { currentUser } = useSelector((state) => state.auth);

    const { gamesList, sportsLeagues, masterData } = useSelector((state) => state.match);
    const [allGames, setAllGames] = useState(null);
    const [leagueList, setLeagues] = useState(null);
    const [leagueEventList, setLeagueEvents] = useState(null);


    // useEffect(() => {
    //     if (gamesList.length > 0) {
    //         setAllGames(gamesList)
    //     } else {
    //         const param = { type: 1 }
    //         if (currentUser.user_id) {
    //             param['user_id'] = currentUser.user_id

    //         }
    //         dispatch(getAllGamesRequest((param), (res) => {
    //             if (res)
    //                 setAllGames(res)
    //         }
    //         ));
    //     }
    // }, [gamesList]);

    const fetchLeagues = (sport_id) => {
        const param = { "sport_id": String(sport_id) }
        dispatch(getSportsLeaguesRequest((param), (res) => {
            setLeagues(res)
        }))

    }
    const fetchLeagueEvents = (league_id) => {
        const param = { "league_id": String(league_id) }
        dispatch(getLeagueEventRequest((param), (res) => {
            setLeagueEvents(res.records)
        }))

    }


    const gotoDetail = (event_id) => {
        toggleClass()
        if (currentUser.username) {
            navigate(`/match-detail/${event_id}`)
        } else {
            navigate(`/detail/${event_id}`)
        }
    }

    function toggleClass() {
        let element = document.getElementById('sideBar_bx');
        element.classList.toggle('custom_toggleClass');
    }



    return (
        <Fragment>
            <aside id="sidebar-multi-level-sidebar" className="z-40 h-full sticky top-0 transition-transform bg-[#fff] dark:bg-[#22262a]" aria-label="Sidebar">
                <div className="h-full pb-2 lg:py-2 overflow-y-auto no-scrollbar">

                    <button onClick={() => toggleClass()} className='block lg:hidden bg-[#F2D61D] fixed text-left p-2 w-[100%] sm:w-[228px]'>
                        <div>
                            Back
                        </div>

                        <img className='text-white absolute right-2 top-2' src={Images.BACKICON} alt="" />

                    </button>

                    <ul className="pt-[59px] lg:pt-0 text-xs">
                        {menuitem.sidebarItems.map((item, key) => (
                            <Fragment>
                                {item.submenu ?
                                    <li className='mb-2' key={key}>
                                        <ul id="dropdown-example" className="py-2 ">
                                            <li className='text-[12px] pl-5 leading-[14.52px] text-[#717171] dark:text-white font-[700] uppercase mb-2 pt-3'>
                                                <p > <span className='ml-1'>{item.name} </span></p>
                                            </li>
                                            <Accordion className='anvMatch_colapse' flush>
                                                {masterData && masterData.sports_master && masterData.sports_master.length > 0 && masterData.sports_master.map((i, idx) => (
                                                    <Accordion.Item key={idx} eventKey={+idx}>
                                                        {i.sport_id != 6 && <Fragment>
                                                            <Accordion.Header className='mainHead_colapse' onClick={() => fetchLeagues(i.sport_id)}>
                                                                <img src={i.icon} alt={i?.sports_name} height="17.79" width="18" />

                                                                <span className='sportsName ' >{i.sports_name}</span>
                                                                <div className='newChevron'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 14 9" fill="none" class=""><path d="M1.48785 2.00005L7.01228 7.97756L12.4878 1.95517" class=" stroke-[#9FA0A2] dark:stroke-[#fff]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                                </div>
                                                            </Accordion.Header>
                                                        </Fragment>}

                                                        {leagueList && leagueList.length > 0 && leagueList.map((league, lidx) => (
                                                            <Fragment>
                                                                {league.sport_id == i.sport_id &&
                                                                    <Accordion.Body className="customBody">
                                                                        <div>
                                                                            <Accordion className='anvMatch_colapse' flush>
                                                                                <Accordion.Item key={lidx} eventKey={+lidx}>
                                                                                    <Accordion.Header className='subHead_colapse' onClick={() => fetchLeagueEvents(league.league_id)}>
                                                                                        <p className='innrSportsName' >{league.league_name}</p>

                                                                                        <div className='newChevron'>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 14 9" fill="none" class=""><path d="M1.48785 2.00005L7.01228 7.97756L12.4878 1.95517" class=" stroke-[#9FA0A2] dark:stroke-[#fff]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                                                        </div>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body className='inrMatchs_body'>
                                                                                        <ul>
                                                                                            {leagueEventList && leagueEventList.length > 0 && leagueEventList.map((event, eidx) => (
                                                                                                <Fragment>
                                                                                                    {event.league_id == league.league_id &&
                                                                                                        <li>
                                                                                                            <div onClick={() => gotoDetail(event.event_id)} className='inr-match-link cursor-pointer' href="">
                                                                                                                <p className='line-clamp-2 overflow-hidden text-ellipsis'>{event.event_name}</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    }
                                                                                                </Fragment>))}
                                                                                        </ul>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            </Accordion>
                                                                        </div>
                                                                    </Accordion.Body>}
                                                            </Fragment>
                                                        ))}


                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>

                                        </ul>
                                    </li>
                                    :
                                    <li className='mb-2' >
                                        <NavLink onClick={() => toggleClass()}
                                            to={item.link == "/all-sports" ?
                                                (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/all-sports'
                                                    : '/login')
                                                : item.link == "/casino" ?
                                                    (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/casino'
                                                        : '/casino-market')
                                                    : item.link == "/inplay" ?
                                                        (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/inplay'
                                                            : '/in-play')
                                                        : item.link}
                                            className={({ isActive }) => `flex items-center cursor-pointer text-[14px] p-[10px_10px_10px_20px] leading-[16.94px] text-[#424242] font-[500] dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${isActive ? "bg-gray-100 dark:bg-gray-700" : ""}`}>
                                            <img src={item.icon} alt="" height="17.79" width="18" />
                                            <span className="ml-3">{item.name}</span>
                                        </NavLink>
                                    </li>}
                            </Fragment>
                        ))}
                    </ul>
                </div>

            </aside>
            <div onClick={() => toggleClass()} className='backDrop2'></div>
        </Fragment>
    )
}

export default Sidebar